@font-face {
  font-family: SilomBol;
  src: url(./fonts/SilomBol.ttf);
}

body {
  margin: 0;
  padding: 0;
  background-color: #171717;
  background-image: url("./images/v1-Simple-synth-wave-style-background-4K.png");
  background-repeat: no-repeat;
  background-position: top; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  font-family: "Poppins", sans-serif;
  height: 100vh;
  overflow: hidden;
  max-height: 100%;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.mainsection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 6.4446831364124595vh 2.9vw 0 6.4446831364124595vh;
}

.parent {
  height: 50vh;
  width: 50vw;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  padding: 2.5vh;
  border-radius: 10px;
}
.parent-bg {
  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
}
.title-img-loaded {
  width: 500px;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 2.5vh;
  margin-bottom: 3vh;
}
.animate {
  -webkit-animation: scale-up-center 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: scale-up-center 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.grid-div {
  display: grid;
  position: relative;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.purchasable-slot {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: animateToUp 0.25s;
}
.uil-shopping-bag {
  font-size: 1.5rem;
  color: #9700ff;
}
.MyAlgohidden {
  display: none !important;
}
.grid-disabled {
  pointer-events: none;
}
#pick {
  display: none;
}
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltiptext2 {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 4;
  bottom: 90%;
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.tooltiptext2::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.MyAlgo:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.MyAlgo2:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.MyAlgo:disabled {
  background-color: gray;
  opacity: 50%;
  pointer-events: none;
}
.MyAlgo2:disabled {
  background-color: gray;
  opacity: 50%;
  pointer-events: none;
}
.colorPicker:hover .tooltiptext2 {
  visibility: hidden;
  opacity: 0;
}
.colorPickSelector {
  border-radius: 5px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  -ms-transition: all linear 0.2s;
  -o-transition: all linear 0.2s;
  transition: all linear 0.2s;
}
.grid-div:hover {
  transform: scale(1.5);
  background-color: gray;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  z-index: 1;
}
.EditState-hidden {
  display: none;
}
.selected-grid-container-hidden {
  display: none !important;
}
.selected-grid-container {
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  padding: 20px 10;
  width: 40%;
  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
}
.Details-heading {
  font-size: 5rem;
  font-weight: 400;
  color: #9700ff;
}
.detail-box {
  height: 30vh;
  flex: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2.5vh;
}
.box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.history-container {
  width: 12%;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.25);
  margin-top: 14px;
  border-radius: 5px;
}

.history-box {
  height: 25%;
  width: 100%;
  cursor: pointer;
  /* border-radius: 5px 5px 0 0; */
}

.color-picker-img {
  height: 1.5rem;
  z-index: 1;
}

.selected-grid {
  height: 20vh;
  width: 60%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  margin-bottom: 2.5vh;
}
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.connection-img {
  height: 3vh;
  margin-right: 0.5vw;
}
.pixels {
  height: auto;
  cursor: cell;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pixels:hover {
  filter: brightness(80%);
}
.info-container {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5vh;
}

.picker {
  height: 100px !important;
  width: 100%;
}

.connect-grid {
  height: 100%;
}
.MyAlgo {
  background-color: #1e51ad;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-family: "Poppins";
  margin-top: 2.5vh;
  transition: all 1s ease;
}
.MyAlgo:hover {
  filter: brightness(80%);
}
.MyAlgo2 {
  background-color: #1e51ad;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-family: "Poppins";
  margin-top: 2.5vh;
  transition: all 1s ease;
}
.MyAlgo2:hover {
  filter: brightness(80%);
}
.EditState {
  margin-top: 1vh;
  margin-right: 6vw;
}
.EditLink {
  margin-right: 6.5vw;
  outline: none;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid #111111;
  padding: 0.6rem;
  color: #f6f6f6;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
}
#slot-link {
  color: #fff;
  margin-bottom: 1vh;
}
.EditLink::placeholder {
  color: #f6f6f6;
  opacity: 0.5;
}
input[text]:out-of-range {
  border-color: red;
}
.MyAlgoHidden {
  display: none;
}
.MyAlgoHidden2 {
  display: none;
}
.pickers-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80%;
  flex-direction: column;
  margin-top: 1vh;
}
.colorPicker {
  outline: none;
  border: none;
  border-radius: 5px;
  height: 80%;
  width: 100%;
  padding: 1vh;
  align-self: start;
  font-family: "Poppins";
  cursor: pointer;
  background-color: #000000;
  color: #fff;
  justify-self: start;
  /* margin-top: 1.5vh; */
}
.color-input-picker {
  margin-bottom: 10vh;
}
/* TOASTER */
#old-color {
  text-decoration: underline;
  color: #fff;
  font-family: "Poppins";
}
.toast-title {
  font-weight: bold;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1;
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  /* -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40); */
  filter: alpha(opacity=40);
}
.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  /*overrides*/
}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 10px 10px 10px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  font-size: 0.9rem;
  color: #ffffff;
}
#toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center;
}
#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}
#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}
#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}
#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toast {
  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
}
.toast-success {
  color: #fdfdfd !important;
  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
}
.toast-error {
  color: #fdfdfd !important;
  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
}
.toast-info {
  color: #fdfdfd !important;
  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
}
.toast-warning {
  color: #fdfdfd !important;

  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
.bannerimg {
  height: 10vh;
}
.link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2vw;
}
.twitter-icon {
  margin-right: 1.5vw;
}
.algogems-icon {
  margin-right: 1.5vw;
}
.icon-link {
  height: 3vh;
  cursor: pointer;
  transition: all 0.2s ease;
}
.icon-link:hover {
  transform: scale(1.15);
}
.banner-img {
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  padding: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hidden {
  display: none;
}
.switch-container {
  position: relative;
  align-self: flex-start;
  cursor: pointer;
  width: 40%;
}
.picker-container {
  position: relative;
  cursor: pointer;
  margin-top: 1vh;
}
.picker-tooltip {
  visibility: hidden;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  width: 120px;
  bottom: 100%;
  left: 0%;
  margin-left: -40px;
  font-size: 0.8rem;
  /* white-space: nowrap; */
  z-index: 1;
}
.picker-container:hover .picker-tooltip {
  visibility: visible;
}
.uil-paint-tool,
.uil-times {
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  padding: 0.1rem 0.3rem;
  margin-bottom: 10vh;
}
.tooltip {
  visibility: hidden;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  width: 120px;
  bottom: 100%;
  left: 10%;
  margin-left: -60px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1;
}
.switch-container:hover .tooltip {
  visibility: visible;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transform: 0.5s;
  border-radius: 34px;
}
.switch input {
  display: none;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.2s;
  border-radius: 50px;
}
input:checked + .slider {
  background-color: #9700ff;
}
input:checked + .slider:before {
  transform: translate(20px);
}
/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .picker-container {
    width: 70%;
  }
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px;
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 1440px) {
  .history-container {
    width: 10%;
    height: 80%;
    margin-left: 15px;
    margin-top: 6px;
  }

  .colorPicker {
    font-size: 0.5rem;
    text-align: center;
    margin-top: 0.5vh;
  }
  .selected-grid-container {
    width: 40%;
    height: 300px;
  }
  .selected-grid {
    width: 40%;
    height: 17vh;
  }
  .detail-box {
    width: 80%;
  }
  .EditLink {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .selected-grid {
    width: 80%;
    height: 24vh;
  }
  .selected-grid-container {
    width: 60%;
    height: 300px;
  }
  .picker-container {
    width: 60%;
  }
  .history-container {
    width: 25%;
    height: 80%;
    margin-left: 15px;
  }

  .history-box {
    height: 25%;
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .selected-grid-container {
    width: 30%;
    flex-direction: column;
    height: 100%;
  }
  .detail-box {
    height: 20vh;
  }
  .container {
    width: 60%;
  }
  .parent {
    width: 100%;
    height: 35vh;
  }
  .selected-grid {
    height: 15vh;
    width: 100%;
  }
  .parent {
    height: 30vh;
  }
  .bannerimg {
    height: 7vh;
    margin-bottom: 0.5vh;
  }
  .mainsection {
    padding: 6.4446831364124595vh 0;
  }
  .box {
    flex-direction: column;
  }
  .picker-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
  }
  .colorPicker {
    width: 100%;
    max-width: 100% !important;
  }
  .EditLink {
    margin-top: 1vh;
    margin-right: 0;
    width: 90%;
  }
  .EditState {
    margin-right: 0;
  }
}
@media screen and (max-width: 480px) {
  .box {
    align-items: center;
  }
  body {
    height: 155vh;
  }
  .selected-grid-container {
    width: 90%;
    height: 100%;
    padding: 0 0.25rem;
    flex-direction: column;
  }
  .pickers-container {
    width: 100%;
  }
  .colorPicker {
    width: 100%;
    height: 4vh;
    align-self: center;
    max-width: 100% !important;
    margin-bottom: 2vh;
  }
  .history-container {
    width: 100%;
  }
  .container {
    width: 65%;
  }
  .parent {
    width: 100%;
    height: 28vh;
  }
  .detail-box {
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .selected-grid {
    height: 20vh;
    width: 50%;
  }
  .bannerimg {
    height: 5vh;
    margin-bottom: 0.5vh;
  }
  .box {
    flex-direction: column;
    height: 100%;
  }
  .EditState {
    margin-top: 1vh;
    margin-right: 0;
    width: 50%;
  }
  .EditLink {
    margin-top: 1vh;
    margin-right: 0;
  }
  .history-container {
    padding: 0;
    margin-right: 15px;
    height: 5vh;
    margin-bottom: 2vh;
    width: 100%;
  }
  .history-box {
    height: 25%;
    width: 100%;
    cursor: pointer;
  }
}

.d-flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 768px) {
  .d-flex-row {
    flex-direction: column;
  }
}

@keyframes animateToUp {
  from {
    opacity: 0;
    bottom: -50%;
  }
  to {
    opacity: 1;
    bottom: 0;
  }
}
